<template>
<div id="EditStudent">

<!-- notification -->
<v-snackbar v-model="notificationToggle" :color="notificationType" :timeout="5000" :top="true"> {{ notificationMsg }}
  <v-btn dark text @click="notificationToggle = false" > Close </v-btn>
</v-snackbar>

<!-- add form -->
<v-form @submit.prevent="EditStudent">
<v-container class="pa-5">
<v-card class="pa-2 elevation-5">
  <v-card-title>
    <h3>Edit Student</h3>
  </v-card-title>
  <v-card-text class="pa-2">
    <v-row>
      <!-- edit form -->
      <!-- file upload -->
      <v-col cols="12" sm="6" md="3">
        <v-file-input v-model="files" color="deep-purple accent-4" single-line label="Upload Image"
        prepend-icon="mdi-google-photos" accept="image/*">
        </v-file-input>
      </v-col>
      <!-- other fields -->
      <v-col cols="12" sm="6" md="2">
          <v-text-field label="StudentID" :error="$v.studentID.$error" :error-messages="StudentIDErrors"
          v-model.trim="studentID" @input="$v.studentID.$touch()" required></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
          <v-text-field label="Name" :error="$v.Name.$error" :error-messages="NameErrors"
          v-model.trim="Name" @input="$v.Name.$touch()" required></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="2">
          <v-text-field label="Class" :error="$v.Class.$error" :error-messages="ClassErrors"
          v-model.trim="Class" @input="$v.Class.$touch()" required></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="2">
          <v-select v-model="Gender" :items="GenderArray" label="Gender"
          persistent-hint return-object single-line ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="2">
          <v-text-field type="tel" label="Date of Birth" :error="$v.DOB.$error" :error-messages="DOBErrors" v-mask="mask" 
          @input="$v.DOB.$touch()" v-model="DOB" required></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="2">
          <v-text-field type="tel" label="Date Of Joining" :error="$v.DOJ.$error" :error-messages="DOJErrors" v-mask="mask" 
          @input="$v.DOJ.$touch()" v-model="DOJ" required></v-text-field>
      </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-select v-model="courseSelected" :items="GetCourses" item-text="title" item-value="title" label="Courses"
          persistent-hint return-object single-line ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="3">
          <v-select v-model="subjectsSelected" :items="GetSubjects" :disabled="subjectExists" label="Subjects"
          persistent-hint return-object single-line multiple ></v-select>
      </v-col>
       <v-col cols="12" sm="6" md="2">
          <v-text-field label="Syllabus" :error="$v.Syllabus.$error" :error-messages="SyllabusErrors"
          v-model.trim="Syllabus" @input="$v.Syllabus.$touch()" required></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
          <v-text-field label="School" :error="$v.School.$error" :error-messages="SchoolErrors"
          v-model="School" @input="$v.School.$touch()" required></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
          <v-text-field label="Address" :error="$v.Address.$error" :error-messages="AddressErrors"
          @input="$v.Address.$touch()" v-model="Address" required></v-text-field>
      </v-col>  
      <v-col cols="12" sm="6" md="3">
          <v-text-field label="Father's Name" :error="$v.FName.$error" :error-messages="FNameErrors"
          @input="$v.FName.$touch()" v-model="FName" required></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
          <v-text-field label="Mother's Name" :error="$v.MName.$error" :error-messages="MNameErrors"
          @input="$v.MName.$touch()" v-model="MName" required></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="2">
          <v-text-field label="Fathers's Job" :error="$v.FJob.$error" :error-messages="FJobErrors"
          @input="$v.FJob.$touch()" v-model="FJob" required></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="2">
          <v-text-field label="Mother's Job" :error="$v.MJob.$error" :error-messages="MJobErrors"
          @input="$v.MJob.$touch()" v-model="MJob" required></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
          <v-text-field type="number" label="Father's Mob" :error="$v.FMob.$error" :error-messages="FMobErrors"
          @input="$v.FMob.$touch()" v-model="FMob" required></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
          <v-text-field type="number" label="Mother's Mob" :error="$v.MMob.$error" :error-messages="MMobErrors"
          @input="$v.MMob.$touch()" v-model="MMob" required></v-text-field>
      </v-col>
       <v-col cols="12" sm="6" md="2">
          <v-select v-model="statusSelected" :items="statusArray" label="Status"
          persistent-hint return-object single-line ></v-select>
      </v-col>
    </v-row>
  </v-card-text>
  <v-card-actions class="pa-3">
      <v-btn type="submit" class="success elevation-10" :disabled="disbtn">Update Student</v-btn>
      <v-btn @click="$router.go(-1)" class="secondary elevation-10">back</v-btn>
  </v-card-actions>

  <!-- show overlay when add item -->
  <v-fade-transition>
  <v-overlay absolute :value="disbtn">
    <v-progress-circular color="yellow" indeterminate width="15" size="100"></v-progress-circular>
  </v-overlay>
  </v-fade-transition>

</v-card>
</v-container>
</v-form>


</div>
</template>

<script>
// imports
import { required, minLength } from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/storage";

// scripts
export default {

    name: 'EditStudent',

    mounted(){
      this.StudentData != null ? this.assignData(this.StudentData) : null;
    },

    data(){return{
        // app flags
        notificationToggle: false,notificationMsg: 'hai',notificationType: 'hui',disbtn: false,

        // student flags
        studentID: null,Name: '',Class: null,Syllabus: '',School: '',DOB: '',DOJ: '',FName: '',
        FJob: '',FMob: null,MName: '',MJob: '',MMob: null,Address: '',courseSelected: [],
        statusSelected: '',subjectsSelected: [],mask: '##-##-####',coursesArray: [],
        subjectsArray: [],subjectExists: true,files: [],GenderArray: ['Male', 'Female'],
        Gender: '',statusArray: ['Active', 'Leaver']
    }},

    methods: {

      async EditStudent(){
        
        // conditions before adding to db 
        if(!this.BlankValidation()){ return false}this.disbtn=true;
        // if no subjects are selected and course has subjects then add all subjects
        if(this.subjectsSelected.length == 0 && this.courseSelected.subjects.length != 0) { 
          this.subjectsSelected = this.courseSelected.subjects.length > 1 ? ['All'] : [];
        }
        // if a course has only one subject then also give empty field
        if(this.courseSelected.subjects.length == 1 ){this.subjectsSelected = [];}

        // check if all exists in subjects array
        let index =  this.subjectsSelected.indexOf('All');
        if(index !== -1 && this.subjectsSelected.length > 1){
          this.subjectsSelected.splice(index, 1);
        }

        let student_Id_Old = this.selectedStudentFromLoop.studentID;
        let student_Id_New = Number(this.studentID);

        // db queries
        // actual update statement
        // get document where student id equals selected one
        await firebase.firestore().collection("studentDetails").where("studentID", "==", student_Id_Old).get().then(
         (res) => {

            // get document reference
            let key =  res.docs[0].id;

            // update all datas(sorry for cramping it up)
            firebase.firestore().collection("studentDetails").doc(res.docs[0].id).update({
                "studentID": student_Id_New,"name": this.Name,"class": this.Class,"syllabus": this.Syllabus,
                "school": this.School,"DOB": this.DOB,"DOJ": this.DOJ,"fatherName": this.FName,
                "fatherOccupation": this.FJob,"fatherMob": this.FMob,"motherName": this.MName,
                "motherOccupation": this.MJob,"motherMob": this.MMob,"course": this.courseSelected.title,
                "subjects": this.subjectsSelected,"address": this.Address,"status": this.statusSelected,"gender": this.Gender,
            }).then((succ) => {


              // update comment slug as well
              firebase.firestore().collection("studentAwards").where("studentID", "==", student_Id_Old).get().then(
              (doc) => {
                if(doc.docs.length != 0){
                  // Once we get the results, begin a batch
                  var batch = firebase.firestore().batch();
                  doc.forEach(function(q) {
                      // For each doc, add a update operation to the batch
                      batch.update(q.ref, {"studentID": student_Id_New});
                  });
                  // Commit the batch
                  batch.commit().then((s) => {this.ImageUpload(key)});
                }else{ this.ImageUpload(key); }
              });


            }).catch((err) => {
                // spit out error
                this.disbtn = false; console.log(err)
                this.notificationService("Server Error, Try After Some Time Or Call Developer", "red darken-1");
            });
                
         }).catch((err) => {
              // spit out error
              this.disbtn = false; console.log(err)
              this.notificationService("Server Error, Try After Some Time Or Call Developer", "red darken-1");
         });

      },

      // image upload function
      ImageUpload(key){

        // vars
        let imageFile;let imageFileName;let ext;let imageUrl;;let task;
        // check if image exists, run Final
        if(this.files.length == 0) { return this.Final(); }
        // actual add file
        imageFile = this.files;
        // get filename
        imageFileName = imageFile.name;
        // get extention of filename
        ext = imageFileName.slice(imageFileName.lastIndexOf('.'));
        // get a reference to store image in firebase storage
        let storageRef = firebase.storage().ref('students/' + key + ext);
        // upload image
        storageRef.put(imageFile).then((snapshot) => {
          // get download url and update db
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            firebase.firestore().collection('studentDetails').doc(key).update({ imageUrl: downloadURL})
            .then((res) => { this.Final(); });
          });
        }).catch((err) => {
           // spit out error
          this.disbtn = false;   
          this.notificationService("Server Error, Image Upload Failed, Try After Some Time", "red darken-1");
        });
   
      },

      // run after all
      Final(){
          this.ResetFields(); 
          this.notificationService("Student Updated SuccessFully!", "success");
          this.disbtn = false;   
          this.$store.dispatch('fetchStudentData');
          setTimeout(()=>{ this.$router.push('/students'); }, 1000); 
      },

      // assign curresponsing data to student
      assignData(selectedData){
        if(selectedData.length > 0){
            this.subjectsSelected = [];
            selectedData.forEach((sd) => {
            this.studentID = sd.studentID, this.Name = sd.name,
            this.Class = sd.class, this.Syllabus = sd.syllabus,
            this.School = sd.school, this.DOB = sd.DOB, this.DOJ = sd.DOJ,
            this.FName = sd.fatherName, this.FJob = sd.fatherOccupation,
            this.FMob = sd.fatherMob, this.MName = sd.motherName,this.imgURL = sd.imageUrl,
            this.MJob = sd.motherOccupation, this.MMob = sd.motherMob,this.Gender = sd.gender,
            this.Address = sd.address, this.selectedStudentFromLoop = sd;
            this.statusSelected = sd.status;sd.subjects.forEach((ss) => {this.subjectsSelected.push(ss)});
            let data = this.GetCourses.filter(c => c.title == sd.course);
            this.courseSelected = data != null ? data[0] : null;
        });}else{return null}
      },
         

      // reset fields
      ResetFields(){     
        this.files = [];this.studentID = null;this.Name = '';this.Class = null;this.Syllabus = '';this.School = '';this.DOB = '';
        this.DOJ = '';this.FName = '';this.FJob = '';this.FMob = null;this.MName = '';this.MJob = '';
        this.MMob = null;this.Address = '';this.courseSelected = [];this.statusSelected = '';this.subjectsSelected = [];
        this.$v.$reset();
      },

      // check if all fileds are empty   
      BlankValidation(){
        if(this.files){
         if(this.files.size / 1024 >= 2048){this.notificationService("File Should be Under 2MB!", "red darken-1");return false;}
        }
        if(this.studentID == '' || this.Name == '' || this.Class == '' || this.Syllabus == '' ||
         this.School == '' || this.DOB == '' || this.DOJ == '' || this.FName == '' ||this.FJob == '' ||
         this.FMob == null || this.FMob == 0 || this.MName == '' || this.MJob == '' || this.MMob == null ||
         this.MMob == 0 ||this.Address == '' || this.courseSelected.length == 0 || this.Gender == '')
        {this.notificationService("Fill All Fields!", "red darken-1"); return false;}else{return true;}
      },
      
      // error pusher for vuelidate   
      ErrorPusher(basic, extra, msg){
        const errors = []
        if (!basic) return errors
        !extra && errors.push(msg);
        return errors;
      },

      // notification service
      notificationService(msg, type){
        this.notificationType = type;
        this.notificationMsg = msg;
        this.notificationToggle = true;
      },

    },

    // watch
    watch: {
        // set subject selection active or inactive based on course
        courseSelected : function(){
          if(this.courseSelected.subjects){
            return this.subjectExists = this.courseSelected.subjects.length > 1 ? false : true;
          }else{
            return null;
          }
        }
    },

    // vue-the-mask related stuff
    directives: { mask },

    // validations
    validations: {
        studentID: { required }, Name: { required },
        Class: { required }, Syllabus: { required },
        School: { required }, DOB: { required, minLength: minLength(10) },
        DOJ: { required, minLength: minLength(10) }, FName: { required },
        FJob: { required }, FMob: { required },
        MName: { required }, MJob: { required },
        MMob: { required }, Address: { required },
        courseSelected: { required }, subjectsSelected: { required },
    },

    computed: {

      // get id of student
      StudentId(){         
          return this.$route.params.id != null ? this.$route.params.id : null;
      },

      // get data of student 
      StudentData(){
        if(this.StudentId != null) {
            let data =  this.$store.getters.GetStudentWithID(this.StudentId);
            return data.length != 0 ? data : this.$router.push("/students");
        }else{ return this.$router.push("/students"); }             
      },

      GetCourses(){
        return this.$store.getters.GetAllCourses;
      },

      GetSubjects(){
        let data;
        let courses = this.GetCourses.length != 0 ? this.GetCourses.filter(course => course.subjects.length != 0) : null;   
        let subjects = courses != null ? courses.filter(s => s.title == this.courseSelected.title).map(c =>  c.subjects) : null;
        data = subjects.length != 0 ? subjects[0] : null;
        return data;
      },
      
      // basically in computed, i added validations  
      // sorry, i cant find a way to make this even smaller : -D
      StudentIDErrors () { return this.ErrorPusher(this.$v.studentID.$dirty, this.$v.studentID.required, 'Student ID is Required'); },
      NameErrors () { return this.ErrorPusher(this.$v.Name.$dirty, this.$v.Name.required, 'Name is Required'); },
      ClassErrors () { return this.ErrorPusher(this.$v.Class.$dirty, this.$v.Class.required, 'Class is Required'); },
      SyllabusErrors () { return this.ErrorPusher(this.$v.Syllabus.$dirty, this.$v.Syllabus.required, 'Syllabus is Required'); },
      SchoolErrors () { return this.ErrorPusher(this.$v.School.$dirty, this.$v.School.required, 'School is Required'); },
      DOBErrors () { return this.ErrorPusher(this.$v.DOB.$dirty, this.$v.DOB.minLength, 'Date Of Birth is Required (dd-mm-yyyy)'); },
      DOJErrors () { return this.ErrorPusher(this.$v.DOJ.$dirty, this.$v.DOJ.minLength, 'Date Of Joining is Required (dd-mm-yyyy)'); },
      FNameErrors () { return this.ErrorPusher(this.$v.FName.$dirty, this.$v.FName.required, 'Father\'s Name is Required'); },
      FJobErrors () { return this.ErrorPusher(this.$v.FJob.$dirty, this.$v.FJob.required, 'Father\'s Job is Required'); },
      FMobErrors () { return this.ErrorPusher(this.$v.FMob.$dirty, this.$v.FMob.required, 'Father\'s Number is Required');},
      MNameErrors () { return this.ErrorPusher(this.$v.MName.$dirty, this.$v.MName.required, 'Mother\'s Name is Required');},
      MJobErrors () { return this.ErrorPusher(this.$v.MJob.$dirty, this.$v.MJob.required, 'Mother\'s Job is Required'); },
      MMobErrors () { return this.ErrorPusher(this.$v.MMob.$dirty, this.$v.MMob.required, 'Mother\'s Number is Required');},
      AddressErrors () { return this.ErrorPusher(this.$v.Address.$dirty, this.$v.Address.required, 'Address is Required'); },
    }
}
</script>
